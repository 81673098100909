$(".menu-btn").on('click', function () {//ボタンがクリックされたら
	$(this).toggleClass('active');//ボタン自身に activeクラスを付与し
    $("#g-nav").toggleClass('panelactive');//ナビゲーションにpanelactiveクラスを付与
    $(".circle-bg").toggleClass('circleactive');//丸背景にcircleactiveクラスを付与
});

$("#g-nav a").on('click', function () {//ナビゲーションのリンクがクリックされたら
    $(".menu-btn").removeClass('active');//ボタンの activeクラスを除去し
    $("#g-nav").removeClass('panelactive');//ナビゲーションのpanelactiveクラスを除去
    $(".circle-bg").removeClass('circleactive');//丸背景のcircleactiveクラスを除去
});

const setFillHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  
  // ここからリサイズの対応
  let vw = window.innerWidth;
  window.addEventListener('resize',setFillHeight);
  window.addEventListener('resize', () => {
    if (vw === window.innerWidth) {
    // 画面の横幅にサイズ変動がないので処理を終える
      return;
    }
  
    // 画面の横幅のサイズ変動があった時のみ高さを再計算する
    vw = window.innerWidth;
    setFillHeight();
  });
  
  // 実行
  setFillHeight();